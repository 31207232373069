export enum WebAnalyticsPageEnum {
    main = '/',
    notFound = '/404',
}

export enum WebAnalyticsTouchPointEnum {
    mobile = 'mobile',
    web = 'web',
    webview = 'webview',
}

export enum WebAnalyticsEventEnum {
    scrn = 'scrn',
    vntMain = 'vntMain',
    vntCross = 'vntCross',
    vntEvent = 'vntEvent',
    vntLogin = 'vntLogin',
    vntForm = 'vntForm',
}

export enum WebAnalyticsEventCategoryEnum {
    menu = 'menu',
    authorization = 'avtorizaciya',
    mainPage = 'glavnaya',
    social = 'social',
    app = 'app',
    crossElements = 'kross_elementy',
    connectionToService = 'podkluchenie_k_servisu',
    mtsAbonent = 'mts_abonent',
    appForm = 'forma_zayavki',
    partners = 'producty_partnerov',
    referralProduct = 'referral_product',
    inviteAgent = 'privedi_agenta',
    footer = 'footer',
    agencyProducts = 'agentskie_produkty',
    createNewLead = 'sozdat_novyi_lid',
    createNewLeadRef = 'sozdat_novyi_lid_ref',
    createNewLeadLKNA = 'sozdat_novyi_lid_lkna',
    promotionalCode = 'promokody',
}

export enum WebAnalyticsEventActionEnum {
    menuClick = 'menu_click',
    confirmed = 'confirmed',
    cardOpen = 'card_open',
    linkClick = 'link_click',
    elementClick = 'element_click',
    buttonClick = 'button_click',
    socialClick = 'social_click',
    appClick = 'app_click',
    rejected = 'rejected',
    cardClick = 'card_click',
    docClick = 'doc_click',
    formAdd = 'form_add',
    checkboxClick = 'checkbox_click',
}

export enum WebAnalyticsEventContentEnum {
    serviceCards = 'kartochki_servisov',
    thereStillQuestions = 'ostalis_voprosy',
    fillingForm = 'zapolnenie_formy',
    topBlock = 'verhnii_blok',
    exampleOfRemuneration = 'primer_rascheta_voznagrazhdeniya',
    howItWorks = 'kak_eto_rabotaet',
    productList = 'spisok_productov',
    apiMtsAgents = 'api_mts_agenty',
    questionsAndAnswers = 'voprosy_i_otvety',
    partnersProducts = 'producty_partnerov',
    mtsEcosystemProducts = 'producty_ekosistemy_mts',
}

export enum WebAnalyticsEventContextEnum {
    step1 = 'step1',
    step3 = 'step3',
}

export enum WebAnalyticsButtonLocationEnum {
    header = 'header',
    popup = 'popup',
    footer = 'footer',
}

export enum WebAnalyticsActionGroupEnum {
    nonInteractions = 'non_interactions',
    interactions = 'interactions',
    conversions = 'conversions',
}
